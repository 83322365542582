import {ReactNode} from 'react'
import {MenuComponent} from '../assets/ts/components'
import {ID} from './crud-helper/models'

type WithChildren = {
  children?: ReactNode
  classId?: ID
}

const reInitMenu = () => {
  setTimeout(() => {
    MenuComponent.reinitialization()
  }, 500)
}

export {type WithChildren, reInitMenu}
