/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { FC } from "react";
import { Link } from "react-router-dom";
import {
  defaultAlerts,
  defaultLogs,
  KTIcon,
  toAbsoluteUrl,
  useIllustrationsPath,
} from "../../../helpers";

const HeaderNotificationsMenu: FC = () => (
  <div
    className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px"
    data-kt-menu="true"
    style={{
      zIndex: "107",
      position: "fixed",
      inset: "0px 0px auto auto",
      margin: "0px",
      transform: "translate3d(-126px, -158.5px, 0px)",
    }}
    data-popper-placement="bottom-end"
  >
    <div className="card">
      {/* <!--begin::Card header--> */}
      <div className="card-header">
        {/* <!--begin::Card title--> */}
        <div className="card-title">Quick Links</div>
        {/* <!--end::Card title-->

            <!--begin::Card toolbar--> */}
        <div className="card-toolbar">
        
          {/* <button
            type="button"
            className="btn btn-sm btn-icon btn-active-light-primary me-n3"
            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
            data-kt-menu-placement="bottom-end"
          >
            <i className="ki-duotone ki-setting-3 fs-2">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
              <span className="path5"></span>
            </i>{" "}
          </button> */}

          {/* <!--begin::Menu 3--> */}
          {/* <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">
                Payments
              </div>
            </div>
            <div className="menu-item px-3">
              <a href="#" className="menu-link px-3">
                Create Invoice
              </a>
            </div>
            <div className="menu-item px-3">
              <a href="#" className="menu-link flex-stack px-3">
                Create Payment
                <span
                  className="ms-2"
                  data-bs-toggle="tooltip"
                  aria-label="Specify a target name for future usage and reference"
                  data-bs-original-title="Specify a target name for future usage and reference"
                  data-kt-initialized="1"
                >
                  <i className="ki-duotone ki-information fs-6">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                  </i>{" "}
                </span>
              </a>
            </div>
            <div className="menu-item px-3">
              <a href="#" className="menu-link px-3">
                Generate Bill
              </a>
            </div>
            <div
              className="menu-item px-3"
              data-kt-menu-trigger="hover"
              data-kt-menu-placement="right-end"
            >
              <a href="#" className="menu-link px-3">
                <span className="menu-title">Subscription</span>
                <span className="menu-arrow"></span>
              </a>
              <div className="menu-sub menu-sub-dropdown w-175px py-4">
                <div className="menu-item px-3">
                  <a href="#" className="menu-link px-3">
                    Plans
                  </a>
                </div>
                <div className="menu-item px-3">
                  <a href="#" className="menu-link px-3">
                    Billing
                  </a>
                </div>  
                <div className="menu-item px-3">
                  <a href="#" className="menu-link px-3">
                    Statements
                  </a>
                </div>
             

                <div className="separator my-2"></div>
                <div className="menu-item px-3">
                  <div className="menu-content px-3">
                    <label className="form-check form-switch form-check-custom form-check-solid">
                      <input
                        className="form-check-input w-30px h-20px"
                        type="checkbox"
                        value="1"
                        checked={true}
                        name="notifications"
                      />
                      <span className="form-check-label text-muted fs-6">
                        Recuring
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="menu-item px-3 my-1">
              <a href="#" className="menu-link px-3">
                Settings
              </a>
            </div>
          </div> */}
          {/* <!--end::Menu 3--> */}
          {/* <!--end::Menu--> */}
        </div>
        {/* <!--end::Card toolbar--> */}
      </div>
      {/* <!--end::Card header--> */}

      {/* <!--begin::Card body--> */}
      <div className="card-body py-5">
        {/* <!--begin::Scroll--> */}
        <div className="mh-450px scroll-y me-n5 pe-5">
          {/* <!--begin::Row--> */}
          <div className="row g-2">
            {/* <!--begin::Col--> */}
            <div className="col-4">
              <a
                href="/content/video/entries"
                className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
              >
                <i className="ki-duotone ki-youtube fs-2hx text-active-primary">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                <span className="fw-semibold">Videos</span>
              </a>
            </div>
            {/* <!--end::Col--> */}
            {/* <!--begin::Col--> */}
            <div className="col-4">
              <a
                href="/content/playlist/series"
                className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
              >
                <i className="ki-duotone ki-abstract-26 fs-2hx text-active-primary">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                <span className="fw-semibold">Series</span>
              </a>
            </div>
            {/* <!--end::Col--> */}
            {/* <!--begin::Col--> */}
            <div className="col-4">
              <a
                href="#"
                className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
              >
                <i className="ki-duotone ki-satellite fs-2hx text-active-primary">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                </i>
                <span className="fw-semibold">Live TV</span>
              </a>
            </div>
            {/* <!--end::Col--> */}
            {/* <!--begin::Col--> */}
            <div className="col-4">
              <a
                href="/monetization/plans"
                className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
              >
                <i className="ki-duotone ki-wallet fs-2hx text-active-primary">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                </i>
                <span className="fw-semibold">Monetization</span>
              </a>
            </div>
            {/* <!--end::Col--> */}
            {/* <!--begin::Col--> */}
            <div className="col-4">
              <a
                href="/manage/users/list"
                className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
              >
                <i className="ki-duotone ki-people fs-2hx text-active-primary">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                </i>
                <span className="fw-semibold">Customers</span>
              </a>
            </div>
            {/* <!--end::Col--> */}
            {/* <!--begin::Col--> */}
            <div className="col-4">
              <a
                href="/monetization/transaction"
                className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
              >
                <i className="ki-duotone ki-dollar fs-2hx text-active-primary">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                </i>
                <span className="fw-semibold">Transactions</span>
              </a>
            </div>
            {/* <!--end::Col--> */}
            {/* <!--begin::Col--> */}
            <div className="col-4">
              <a
                href="/content/encoding/profile"
                className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
              >
                <i className="ki-duotone ki-code  fs-2hx text-active-primary">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                </i>
                <span className="fw-semibold">Encoding</span>
              </a>
            </div>
            {/* <!--end::Col--> */}
            {/* <!--begin::Col--> */}
            <div className="col-4">
              <a
                href="/manage/production-company"
                className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
              >
                <i className="ki-duotone ki-abstract-39  fs-2hx text-active-primary">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                </i>
                <span className="fw-semibold">Production</span>
              </a>
            </div>
            {/* <!--end::Col--> */}
             {/* <!--begin::Col--> */}
             <div className="col-4">
              <a
                href="/manage/layout/general"
                className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
              >
                 <i className="ki-duotone ki-abstract-27 fs-2hx text-active-primary">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                <span className="fw-semibold">Layouts</span>
              </a>
            </div>
            {/* <!--end::Col--> */}
          </div>
          {/* <!--end::Row--> */}
        </div>
        {/* <!--end::Scroll--> */}
      </div>
      {/* <!--end::Card body--> */}
    </div>
    {/* <!--end::Card--> */}
  </div>
);

export { HeaderNotificationsMenu };
