/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
// import './AuthLayout.scss'
const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById("root");
    document.body.style.setProperty(
      "background-image",
      `url(${toAbsoluteUrl("/media/auth/bgNew.jpeg")})`
    );
    if (root) {
      root.style.height = "100%";
    }
    return () => {
      document.body.style.setProperty("background-image", "none");

      if (root) {
        root.style.height = "auto";
      }
    };
  }, []);

  return (
    <div className="d-flex flex-column flex-column-fluid flex-lg-row">
				<div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
					<div className="d-flex flex-center flex-lg-start flex-column">
						<a href="index.html" className="mb-7">
							<img alt="Logo" src={toAbsoluteUrl("/media/auth/Innocruxlogo.png")} width={200}/>
						</a>
						<h2 className="text-white fw-normal m-0">Your Gateway to Unlimited Entertainment</h2>
					</div>
				</div>
				<div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
					<div className="d-flex bg-body  rounded-4 w-md-600px flex-center flex-lg-start flex-column p-20">
					<Outlet/>
					</div>
				</div>
				{/* <div className=" justify-content-lg-end p-12 p-lg-20 top-50">
					<div className="bg-body  rounded-4 w-md-600px p-20">
						<div className=" flex-column px-lg-10 ">
							
								<Outlet/>
						</div>
						
					</div>
				</div> */}
			</div>
  );
};

export { AuthLayout };



{/* <div className="d-flex flex-stack px-lg-10">
							<div className="me-0"> */}
								{/* <button className="btn btn-flex btn-link btn-color-gray-700 btn-active-color-primary rotate fs-base" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" data-kt-menu-offset="0px, 0px">
									<img data-kt-element="current-lang-flag" className="w-20px h-20px rounded me-3" src={toAbsoluteUrl("/media/flags/united-states.svg")} alt="" />
									<span data-kt-element="current-lang-name" className="me-1">English</span>
									<i className="ki-duotone ki-down fs-5 text-muted rotate-180 m-0"></i>
								</button> */}
								{/* <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-4 fs-7" data-kt-menu="true" id="kt_auth_lang_menu">
									<div className="menu-item px-3">
										<a href="#" className="menu-link d-flex px-5" data-kt-lang="English">
											<span className="symbol symbol-20px me-4">
												<img data-kt-element="lang-flag" className="rounded-1" src={toAbsoluteUrl("/media/flags/united-states.svg")} alt="" />
											</span>
											<span data-kt-element="lang-name">English</span>
										</a>
									</div>
									
								</div> */}
							{/* </div> */}
							{/* <div className="d-flex fw-semibold text-primary fs-base gap-5">
								<a href="/" >Terms</a>
								<a href="/" >Plans</a>
								<a href="/" >Contact Us</a>
							</div> */}
						{/* </div> */}