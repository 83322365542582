import { FC, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";

import clsx from "clsx";
import { checkIsActive, KTIcon, WithChildren } from "../../../../helpers";

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  menuTrigger?: "click" | `{default:'click', lg: 'hover'}`;
  menuPlacement?: "right-start" | "bottom-start" | "left-start";
  hasArrow?: boolean;
  hasBullet?: boolean;
  isMega?: boolean;
};

const MenuInnerWithPrimaryMenu: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  menuTrigger,
  menuPlacement,
  hasArrow = false,
  hasBullet = false,
  isMega = false,
}) => {
  const menuItemRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  //   useEffect(() => {
  //     if (menuItemRef.current && menuTrigger && menuPlacement) {
  //       menuItemRef.current.setAttribute("data-kt-menu-trigger", menuTrigger);
  //       menuItemRef.current.setAttribute("data-kt-menu-placement", menuPlacement);
  //     }
  //   }, [menuTrigger, menuPlacement]);

  return (
    <>
      <div className="d-flex align-items-center">
        {/* <span
          onClick={() => navigate("/dashboard")}
          style={{ cursor: "pointer" }}
        >
          <i
            // style={{ color: "black", fontSize: "22px", paddingRight: "10px" }}
            className="fa fa-angle-left"
            aria-hidden="true"
          ></i>
        </span> */}
      
      </div>
      <div className="menu-item me-lg-1 text-uppercase">
      <div className="menu-item me-lg-1">
       
        <span className="menu-title fs-5"  style={{fontWeight:'600', color: "black",}}>
        <div className="menu-link py-3 btn btn-sm btn-active-color-primary " onClick={()=>navigate('/dashboard')}>
          <i
             style={{ fontSize: "18px" }}
            className="fa fa-angle-left"
            aria-hidden="true"
          ></i></div></span></div>
      </div>
      <div className="menu-item me-lg-1 text-uppercase">
      <div className="menu-item me-lg-1"><div className="menu-link py-3 " >
        <span className="menu-title fs-5"  style={{fontWeight:'600', color: "black", cursor:'default'}}>
         {title}</span></div></div>
      </div>
      <div className="menu-item me-lg-1 ">{children}</div>
    </>

    // <div ref={menuItemRef} className="menu-item menu-lg-down-accordion me-lg-1">
    //   <span
    //     className={clsx("menu-link py-3", {
    //       active: checkIsActive(pathname, to),
    //     })}
    //   >
    //     {hasBullet && (
    //       <span className="menu-bullet">
    //         <span className="bullet bullet-dot"></span>
    //       </span>
    //     )}

    //     {icon && (
    //       <span className="menu-icon">
    //         <KTIcon iconName={icon} className="fs-2" />
    //       </span>
    //     )}

    //     {fontIcon && (
    //       <span className="menu-icon">
    //         <i className={clsx("bi fs-3", fontIcon)}></i>
    //       </span>
    //     )}

    //     <span className="menu-title">{title}</span>

    //     {hasArrow && <span className="menu-arrow"></span>}
    //   </span>
    //   <div
    //   // className={clsx(
    //   //   "menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown",
    //   //   isMega
    //   //     ? "w-100 w-lg-850px p-5 p-lg-5"
    //   //     : "menu-rounded-0 py-lg-4 w-lg-225px"
    //   // )}
    //   // data-kt-menu-dismiss="true"
    //   >
    //     {children}
    //   </div>
    // </div>
  );
};

export { MenuInnerWithPrimaryMenu };
