import { useEffect, useState } from "react";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import { changePassword } from '../core/_requests';
import { KTIcon } from "../../../../_metronic/helpers";

const initialValues = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: ''
};

const forgotPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, 'Minimum 8 characters')
    .max(50, 'Maximum 50 characters')
    .matches(/[A-Z]/, 'Must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Must contain at least one lowercase letter')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Must contain at least one special character')
    .required('New password is required'),
  currentPassword: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .required('Current password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords must match')
    .required('Confirm password is required')
});

 const PasswordChangeEdtModal = ({ setItemId, setPasswordChange }) => {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(false);
      changePassword(values.currentPassword,values.newPassword )
        .then(({ data }) => {
          navigate('/auth/login');
        })
        .catch((error) => {
          setHasErrors(true);
          setErrorMessage(error.response.data.message);
        })
        .finally(() => {
          setLoading(false);
          setSubmitting(false);
        });
    }
  });

  return (
    <>
      <div
        className="modal fade show d-block"
        id="kt_modal_add_user"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Change Password</h2>
              <div
                className="btn btn-icon btn-sm btn-active-icon-primary"
                data-kt-users-modal-action="close"
                onClick={() => {setItemId(false);
                  setPasswordChange(false)}}
                style={{ cursor: "pointer" }}
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              {hasErrors && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>
                    {errorMessage}
                  </div>
                </div>
              )}
              <form
                id="kt_modal_add_user_form"
                className="form"
                noValidate
                onSubmit={formik.handleSubmit}
              >
                <div className="col-sm-12 p-3">
                  <label className="form-label fs-6 fw-bolder text-dark required">
                    Current Password
                  </label>
                  <input
                    placeholder="Enter current password"
                    {...formik.getFieldProps("currentPassword")}
                    className={clsx(
                      "form-control bg-transparent",
                      {
                        "is-invalid":
                          formik.touched.currentPassword && formik.errors.currentPassword,
                      },
                      {
                        "is-valid":
                          formik.touched.currentPassword && !formik.errors.currentPassword,
                      }
                    )}
                    type="password"
                    name="currentPassword"
                    autoComplete="off"
                  />
                  {formik.touched.currentPassword && formik.errors.currentPassword && (
                    <div className="fv-plugins-message-container">
                      <span role="alert" className="text-danger">
                        {formik.errors.currentPassword}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-sm-12 p-3 mb-5">
                  <label className="form-label fs-6 fw-bolder text-dark required">
                    New Password
                  </label>
                  <input
                    placeholder="Enter new password"
                    {...formik.getFieldProps("newPassword")}
                    className={clsx(
                      "form-control bg-transparent",
                      {
                        "is-invalid":
                          formik.touched.newPassword && formik.errors.newPassword,
                      },
                      {
                        "is-valid":
                          formik.touched.newPassword && !formik.errors.newPassword,
                      }
                    )}
                    type="password"
                    name="newPassword"
                    autoComplete="off"
                  />
                  {formik.touched.newPassword && formik.errors.newPassword && (
                    <div className="fv-plugins-message-container">
                      <span role="alert" className="text-danger">
                        {formik.errors.newPassword}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-sm-12 p-3 mb-5">
                  <label className="form-label fs-6 fw-bolder text-dark required">
                    Confirm Password
                  </label>
                  <input
                    placeholder="Confirm new password"
                    {...formik.getFieldProps("confirmPassword")}
                    className={clsx(
                      "form-control bg-transparent",
                      {
                        "is-invalid":
                          formik.touched.confirmPassword && formik.errors.confirmPassword,
                      },
                      {
                        "is-valid":
                          formik.touched.confirmPassword && !formik.errors.confirmPassword,
                      }
                    )}
                    type="password"
                    name="confirmPassword"
                    autoComplete="off"
                  />
                  {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                    <div className="fv-plugins-message-container">
                      <span role="alert" className="text-danger">
                        {formik.errors.confirmPassword}
                      </span>
                    </div>
                  )}
                </div>
                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                  <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
                    <span className='indicator-label'>Submit</span>
                    {loading && (
                      <span className='indicator-progress'>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                 
                    <button
                      type='button'
                      id='kt_login_password_reset_form_cancel_button'
                      className='btn btn-light'
                      disabled={formik.isSubmitting || !formik.isValid}
                      onClick={() => {setItemId(false);
                        setPasswordChange(false)}}
                    >
                      Cancel
                    </button>
               
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
                    }
export { PasswordChangeEdtModal }